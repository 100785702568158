
body{
  width:80%; margin:0 auto;
}
.App {
  font-family: Arial, sans-serif;
  padding: 20px;

}

h1 {
  text-align: center;
}

form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.form-row {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

.form-group {
  flex: 1;
}

label {
  display: block;
  margin-bottom: 5px;
}

input {
  width: 100%;
  padding: 8px;
  margin-bottom: 5px;
  border: 1px solid #ccc;
}

.error {
  color: red;
  font-size: 12px;
}

button {
  padding: 10px 20px;
  background-color: #4CAF50;
  color: white;
  border: none;
  cursor: pointer;
  width:100px;
  margin-right:20px
}

button:hover {
  background-color: #45a049;
}